module.exports = [{
      plugin: require('/home/jason/Sites/mashupgarage/mashupgarage.com-2018/node_modules/gatsby-mdx/gatsby-browser.js'),
      options: {"plugins":[],"defaultLayouts":{"default":"/home/jason/Sites/mashupgarage/mashupgarage.com-2018/src/layouts/ArticleLayout.tsx"},"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-images","options":{"maxWidth":1440}}]},
    },{
      plugin: require('/home/jason/Sites/mashupgarage/mashupgarage.com-2018/node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.mashupgarage.com"},
    },{
      plugin: require('/home/jason/Sites/mashupgarage/mashupgarage.com-2018/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-109832707-2"},
    },{
      plugin: require('/home/jason/Sites/mashupgarage/mashupgarage.com-2018/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
