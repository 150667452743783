import React from 'react'
import CSS from './IntroTextBlock.module.css'

const IntroTextBlock = (props: Props) => {
  const { children } = props
  return <div className={CSS.root}>{children}</div>
}

interface Props {
  children: React.ReactNode
}

export default IntroTextBlock
