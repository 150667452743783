import cn from 'classnames'
import React from 'react'
import FullBlock from './FullBlock'
import CSS from './LargeImageBlock.module.css'

const LargeImageBlock = (props: Props) => {
  const { src, alt, panorama } = props

  return (
    <FullBlock>
      <div className={cn({ [CSS.root]: true, [CSS.isPanorama]: panorama })}>
        <img src={src} alt={alt} className={CSS.image} />
      </div>
    </FullBlock>
  )
}

interface Props {
  src: string
  alt: string

  /** If true, stretch the image horizontally */
  panorama?: boolean
}

export default LargeImageBlock
