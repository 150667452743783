import { ArticleTitleBlock, BrowserScreenshotBlock, DividerBlock, IntroTextBlock, IsoScreenshotBlock, ScreenshotGalleryBlock, OutlineOrnamentBlock } from "../../../../src/blocks";
import React from 'react';
export default {
  ArticleTitleBlock,
  BrowserScreenshotBlock,
  DividerBlock,
  IntroTextBlock,
  IsoScreenshotBlock,
  ScreenshotGalleryBlock,
  OutlineOrnamentBlock,
  React
};