import cn from 'classnames'
import React from 'react'
import CSS from './FigureBlock.module.css'
import FullBlock from './FullBlock'

/**
 * A `<figure>`.
 *
 * Extra properties are available:
 *
 * @param props.wide - Wider
 * @param props.full - Makes it a full-sized figure (like a panorama)
 * @param props.margin - Adds extra margins
 * @param props.border - Adds borders
 * @param props.xmargin - Adds more extra margins
 * @param props.xxmargin - Adds even more extra margins
 */
const FigureBlock = (props: Props) => {
  const { children, caption, full } = props

  const markup = (
    <figure
      className={cn(CSS.root, {
        [CSS.hasBorder]: props.border,
        [CSS.isWide]: props.wide,
        [CSS.hasMargin]: props.margin,
        [CSS.hasXMargin]: props.xmargin,
        [CSS.hasXXMargin]: props.xxmargin
      })}
    >
      <div className={CSS.figure}>{children}</div>
      {caption ? (
        <figcaption className={CSS.caption}>{caption}</figcaption>
      ) : null}
    </figure>
  )

  if (full) {
    return <FullBlock>{markup}</FullBlock>
  } else {
    return markup
  }
}

interface Props {
  children: React.ReactNode
  caption?: React.ReactNode
  full?: boolean
  wide?: boolean
  border?: boolean
  margin?: boolean
  xmargin?: boolean
  xxmargin?: boolean
}

export default FigureBlock
