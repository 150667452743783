import screencastMp4 from "../../../../contents/case-studies/lawadvisor/lawadvisor-screencast.mp4";
import { Link } from 'gatsby';
import EmbeddedVideo from "../../../../src/components/EmbeddedVideo";
import { ArticleTitleBlock, BrowserScreenshotBlock, DefinitionsBlock, OutlineOrnamentBlock } from "../../../../src/blocks";
import React from 'react';
export default {
  screencastMp4,
  Link,
  EmbeddedVideo,
  ArticleTitleBlock,
  BrowserScreenshotBlock,
  DefinitionsBlock,
  OutlineOrnamentBlock,
  React
};