import React from 'react'
import CSS from './CitationBlock.module.css'

/**
 * A block of citation
 *
 * @param props.children Body to render
 * @param props.title The title
 * @param props.href URL to link to
 * @param props.site Site name
 */

const CitationBlock = (props: Props) => {
  const { children, title, site, href } = props
  return (
    <div className={CSS.root}>
      <div className={CSS.content}>{children}</div>
      {href && title ? (
        <div className={CSS.link}>
          <span className={CSS.icon}>
            <span className={CSS.letter}>
              {title.substr(0, 1).toUpperCase()}
            </span>
          </span>
          <a href={href}>
            <strong>{title}</strong>
            {site ? <span>{site}</span> : null}
          </a>
        </div>
      ) : null}
    </div>
  )
}

interface Props {
  children: React.ReactNode
  title?: string
  href?: string
  site?: string
}

export default CitationBlock
