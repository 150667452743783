import React from 'react'
import CSS from './ArticleTitleBlock.module.css'

/**
 * Article title.
 *
 * @param props.title Title
 * @param props.prelude Prelude
 * @param props.children If title isn't given, put it here
 */

const ArticleTitleBlock = (props: Props) => {
  const { title, prelude, children } = props
  if (!title && !children) return null

  return (
    <div className={CSS.root}>
      {prelude ? <div className={CSS.prelude}>{prelude}</div> : null}
      <h1>{title || children}</h1>
    </div>
  )
}

interface Props {
  children?: React.ReactNode
  title?: string
  prelude?: React.ReactNode
}

export default ArticleTitleBlock
