import React from 'react'
import CSS from './IsoImage.module.css'

const IsoImage = (props: Props) => {
  const { width, height, maxWidth, children } = props

  const imageWidth = maxWidth ? Math.min(width, maxWidth) : width
  const imageHeight = imageWidth * (height / width)

  return (
    <div
      className={CSS.root}
      style={{ width: imageWidth * 1.2, height: imageHeight * 1.9 }}
    >
      <div
        className={CSS.screen}
        style={{ width: `${imageWidth}px`, height: `${imageHeight}px` }}
      >
        {children}
      </div>
    </div>
  )
}

IsoImage.defaultProps = {
  maxWidth: null
}

export interface Props {
  maxWidth: number | null
  width: number
  height: number
  children: React.ReactNode
}

export default IsoImage
