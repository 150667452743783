import React from 'react'

/**
 * A video.
 * Best used in a BrowserScreenshotBlock.
 *
 *     <EmbeddedVideo mp4='/path/to.mp4' webm='/path/to.webm' />
 */

const EmbeddedVideo = (props: Props) => {
  const { webm, mp4 } = props

  return (
    <video autoPlay loop>
      {webm ? <source src={webm} type='video/webm; codecs=vp9,vorbis' /> : null}
      {mp4 ? <source src={mp4} type='video/mp4' /> : null}
    </video>
  )
}

interface Props {
  mp4?: string
  webm?: string
}

export default EmbeddedVideo
