import React from 'react'
import FullBlock from '../FullBlock'
import CSS from './BrowserScreenshotBlock.module.css'

/**
 * Isometric screenshot block, useful for "hero"-like sections.
 */

const BrowserScreenshotBlock = (props: Props) => {
  const { children, size, scale, caption, wallpaper } = props
  const [width] = size

  return (
    <FullBlock>
      <div className={CSS.root}>
        {/* Background box */}
        <div className={CSS.box}>{wallpaper || null}</div>

        {/* Text caption, if provided */}
        {caption ? (
          <div className={CSS.caption}>
            <div className={CSS.text}>{caption}</div>
          </div>
        ) : null}

        {/* The image */}
        <span className={CSS.image} style={{ maxWidth: width * scale }}>
          <div className={CSS.toolbar} />
          {children}
        </span>
      </div>
    </FullBlock>
  )
}

interface Props {
  children: React.ReactNode
  caption?: React.ReactNode
  wallpaper?: React.ReactNode
  size: [number, number]
  scale: number
}

BrowserScreenshotBlock.defaultProps = {
  size: [400, 300],
  scale: 1
}

export default BrowserScreenshotBlock
