import screencastWebm from "../../../../contents/case-studies/comic-odyssey/comic-odyssey-screencast.webm";
import screencastMp4 from "../../../../contents/case-studies/comic-odyssey/comic-odyssey-screencast.mp4";
import EmbeddedVideo from "../../../../src/components/EmbeddedVideo";
import { ArticleTitleBlock, BrowserScreenshotBlock, DefinitionsBlock, OutlineOrnamentBlock } from "../../../../src/blocks";
import React from 'react';
export default {
  screencastWebm,
  screencastMp4,
  EmbeddedVideo,
  ArticleTitleBlock,
  BrowserScreenshotBlock,
  DefinitionsBlock,
  OutlineOrnamentBlock,
  React
};