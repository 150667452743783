import { ArticleTitleBlock, ExtremeTitleBlock, DefinitionsBlock, DividerBlock, PanoramaBlock, CitationBlock, ScreenshotGalleryBlock, SpacerBlock } from "../../../../src/blocks";
import React from 'react';
export default {
  ArticleTitleBlock,
  ExtremeTitleBlock,
  DefinitionsBlock,
  DividerBlock,
  PanoramaBlock,
  CitationBlock,
  ScreenshotGalleryBlock,
  SpacerBlock,
  React
};