/*
 * These statements are segregated by empty newlines so not to throw off
 * tslint's "sorted imports" rule. Each group's list is safe to sort, though.
 *
 * This file is imported in both gatsby-browser.js (used in all files) and
 * Layout.tsx. This recommendation came from this article:
 *
 * https://dev.to/stoutlabs/speed-tip-use-typefacesjs-in-gatsby-to-locally-host-fonts-6om
 */
import 'typeface-ibm-plex-sans'
import 'typeface-inter'
import 'typeface-libre-franklin'
import 'typeface-work-sans'

import 'sanitize.css'

import 'github-markdown-css'

import './Layout.css'
