import React from 'react'
import Media from 'react-responsive'
import FullBlock from '../FullBlock'
import IsoImage from './IsoImage'
import CSS from './IsoScreenshotBlock.module.css'

/**
 * Isometric screenshot block, useful for "hero"-like sections.
 */

const IsoScreenshotBlock = (props: Props) => {
  const { children, size, scale, caption, wallpaper } = props
  const [width, height] = size

  return (
    <FullBlock>
      <div className={CSS.root}>
        {/* Background box */}
        <span className={CSS.box}>{wallpaper || null}</span>

        {/* Text caption, if provided */}
        {caption ? (
          <div className={CSS.caption}>
            <div className={CSS.text}>{caption}</div>
          </div>
        ) : null}

        {/* The image */}
        <span className={CSS.image}>
          <Media query='(max-width: 768px)'>
            {isTablet => (
              <Media query='(max-width: 480px)'>
                {isMobile => (
                  <IsoImage
                    width={scale * width}
                    height={scale * height}
                    maxWidth={isMobile ? 450 : isTablet ? 700 : null}
                  >
                    {children}
                  </IsoImage>
                )}
              </Media>
            )}
          </Media>
        </span>
      </div>
    </FullBlock>
  )
}

interface Props {
  children: React.ReactNode
  caption?: React.ReactNode

  /** The image to be placed in the backdrop (`<img src={} />`) */
  wallpaper?: React.ReactNode

  /** The original size of the image */
  size: [number, number]

  /** How much to scale it down */
  scale: number
}

IsoScreenshotBlock.defaultProps = {
  size: [400, 300],
  scale: 1
}

export default IsoScreenshotBlock
