import cn from 'classnames'
import React from 'react'
import FullBlock from '../FullBlock'
import getItems from './getItems'
import CSS from './ScreenshotGalleryBlock.module.css'

/**
 * Showing off screenshots, mobile or desktop
 */

const ScreenshotGalleryBlock = (props: Props) => {
  const { children, wallpaper, size } = props
  const images = getItems(children)

  return (
    <FullBlock>
      <div
        className={cn({
          [CSS.root]: true,
          [CSS.isSizeMobile]: size === 'mobile',
          [CSS.isSizeDesktop]: size === 'desktop'
        })}
      >
        <span className={CSS.box}>{wallpaper || null}</span>
        <ul className={CSS.items}>
          {images.map((item, idx) => {
            return (
              <li className={CSS.item} key={idx}>
                {item}
              </li>
            )
          })}
        </ul>
      </div>
    </FullBlock>
  )
}

interface Props {
  children: React.ReactNode
  wallpaper?: React.ReactNode

  /** The size of the images to be displayed */
  size?: 'mobile' | 'desktop'
}

ScreenshotGalleryBlock.defaultProps = {
  wallpaper: false,
  size: 'mobile'
}

export default ScreenshotGalleryBlock
