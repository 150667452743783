import React from 'react'
import FullBlock from './FullBlock'
import CSS from './PullQuoteBlock.module.css'

const PullQuoteBlock = (props: Props) => {
  const { children } = props
  return (
    <FullBlock>
      <div className={CSS.root}>
        <div className={CSS.text}>{children}</div>
      </div>
    </FullBlock>
  )
}

interface Props {
  children: React.ReactNode
}

export default PullQuoteBlock
