import React from 'react'
import CSS from './FullBlock.module.css'

/**
 * A simple, no-style block that spans the full width. Use this as a base for other blocks.
 */

const FullBlock = (props: Props) => {
  const { children } = props

  return <div className={CSS.root}>{children}</div>
}

interface Props {
  children: React.ReactNode
}

export default FullBlock
