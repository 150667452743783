import React from 'react'
import CSS from './DividerBlock.module.css'

const DividerBlock = () => {
  return (
    <div className={CSS.root}>
      <hr />
    </div>
  )
}

export default DividerBlock
