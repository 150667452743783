import React from 'react'
import CSS from './DefinitionsBlock.module.css'
import FullBlock from './FullBlock'

/**
 * A list of 'key-value' things.
 *
 * @example
 *     <DefinitionsBlock definitions={[
 *       ['Location', 'London, UK'],
 *       ['Product', 'Rails application']
 *     ]} />
 */

const DefinitionsBlock = (props: Props) => {
  const { children, definitions } = props

  if (children) {
    return (
      <FullBlock>
        <div className={CSS.root}>{children}</div>
      </FullBlock>
    )
  } else if (definitions) {
    return (
      <FullBlock>
        <div className={CSS.root}>
          {definitions.map(([term, definition], idx) => (
            <dl key={idx}>
              <dt>{term}</dt>
              <dd>{definition}</dd>
            </dl>
          ))}
        </div>
      </FullBlock>
    )
  }

  return null
}

type Definition = [React.ReactNode, React.ReactNode]

interface Props {
  children?: React.ReactNode
  definitions?: Definition[]
}

export default DefinitionsBlock
