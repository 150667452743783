import cn from 'classnames'
import React from 'react'
import Rellax from 'react-rellax'
import CSS from './ExtremeTitleBlock.module.css'
import FullBlock from './FullBlock'

/**
 * Extreme title block.
 *
 * You can configure which `palette` to use (`'a'`, `'b'`, and so on). Each
 * letter has its own color. B is for Elixir indigo, C is for Ruby red, D is
 * for pink, and so on.
 */

const ExtremeTitleBlock = (props: Props) => {
  const { title, children, palette } = props
  return (
    <FullBlock>
      <div
        className={cn(CSS.root, {
          [CSS.isPaletteA]: palette === 'a',
          [CSS.isPaletteB]: palette === 'b',
          [CSS.isPaletteC]: palette === 'c',
          [CSS.isPaletteD]: palette === 'd'
        })}
      >
        <span className={CSS.ornament} />
        <span className={CSS.ornament} />
        <span className={CSS.ornament} />
        <span className={CSS.ornament} />

        <Rellax speed={4} centered>
          <div className={CSS.text}>
            {title ? <h3 className={CSS.title}>{title}</h3> : null}
            {children}
          </div>
        </Rellax>
      </div>
    </FullBlock>
  )
}

ExtremeTitleBlock.defaultProps = {
  palette: 'b'
}

interface Props {
  title?: React.ReactNode
  children?: React.ReactNode

  /** The color palette to use */
  palette: 'a' | 'b' | 'c' | 'd'
}

export default ExtremeTitleBlock
