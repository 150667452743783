import React from 'react'
import FullBlock from './FullBlock'
import CSS from './PanoramaBlock.module.css'

const PanoramaBlock = (props: Props) => {
  const { children } = props
  return (
    <FullBlock>
      <div className={CSS.root}>{children}</div>
    </FullBlock>
  )
}

interface Props {
  children: React.ReactNode
}

export default PanoramaBlock
