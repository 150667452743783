import React from 'react'

/**
 * Returns the children of all `li` elements combined together.
 * Useful for MDX, where you can have your components's children be an
 * unordered list and be able to traverse them all programatically.
 */

const getItems = (
  root: any /* voodoo is happening here */
): React.ReactNode[] => {
  // Array
  if (Array.isArray(root)) {
    return root.reduce((result, newRoot) => {
      return [...result, ...getItems(newRoot)]
    }, [])
  }

  // Type guard, so those below won't error
  if (!root || !root.props) return []

  // List item
  if (root.props.mdxType === 'li' && root.props && root.props.children) {
    return [...root.props.children]
  }

  // Traverse otherwise
  if (root.props && root.props.children) {
    return getItems(root.props.children)
  }

  return []
}

export default getItems
