import { ArticleTitleBlock, ExtremeTitleBlock, IntroTextBlock, BrowserScreenshotBlock, DefinitionsBlock, DividerBlock, PanoramaBlock, CitationBlock, FigureBlock, PullQuoteBlock } from "../../../../src/blocks";
import React from 'react';
export default {
  ArticleTitleBlock,
  ExtremeTitleBlock,
  IntroTextBlock,
  BrowserScreenshotBlock,
  DefinitionsBlock,
  DividerBlock,
  PanoramaBlock,
  CitationBlock,
  FigureBlock,
  PullQuoteBlock,
  React
};