import { Link } from 'gatsby';
import { ArticleTitleBlock, DefinitionsBlock, BrowserScreenshotBlock, DividerBlock, IntroTextBlock, IsoScreenshotBlock, ScreenshotGalleryBlock, OutlineOrnamentBlock } from "../../../../src/blocks";
import React from 'react';
export default {
  Link,
  ArticleTitleBlock,
  DefinitionsBlock,
  BrowserScreenshotBlock,
  DividerBlock,
  IntroTextBlock,
  IsoScreenshotBlock,
  ScreenshotGalleryBlock,
  OutlineOrnamentBlock,
  React
};