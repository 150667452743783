import React from 'react'
import FullBlock from './FullBlock'
import CSS from './IllustratedTextBlock.module.css'

const IllustratedTextBlock = (props: Props) => {
  const { children, figure } = props

  return (
    <FullBlock>
      <div className={CSS.root}>
        <div className={CSS.figure}>{figure}</div>
        <div className={CSS.text}>{children}</div>
      </div>
    </FullBlock>
  )
}

interface Props {
  figure: React.ReactNode
  children: React.ReactNode
}

export default IllustratedTextBlock
