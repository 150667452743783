import ticketbaseScreencastMp4 from "../../../../contents/case-studies/ticketbase/ticketbase-screencast.mp4";
import EmbeddedVideo from "../../../../src/components/EmbeddedVideo";
import { ArticleTitleBlock, DefinitionsBlock, OutlineOrnamentBlock, BrowserScreenshotBlock } from "../../../../src/blocks";
import React from 'react';
export default {
  ticketbaseScreencastMp4,
  EmbeddedVideo,
  ArticleTitleBlock,
  DefinitionsBlock,
  OutlineOrnamentBlock,
  BrowserScreenshotBlock,
  React
};